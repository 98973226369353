import Input from "@/components/input";
import NeoButton from "@/components/button";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "tool-manager-form",
    components: {
        "neo-input": Input,
        "neo-button": NeoButton,
    },
    props: {
        itemData: {
            require: true,
        },
    },
    data() {
        return {
            tool: {
                tool_name: "",
                key: "",
                username: "",
                password: "",
                client_name: "",
            },
        };
    },
    watch: {
        itemData() {
            this.reset();
        },
    },
    computed: {},
    mounted() {},
    methods: {
        ...mapActions({
            SaveTool: "toolmanager/saveToolManagerData",
        }),
        save() {
            this.tool.tool_name = this.itemData.internal_name;
            this.SaveTool(this.tool)
                .then(function (response) {})
                .catch((error) => {});
        },
        reset() {
            this.tool = {
                tool_name: "",
                key: "",
                username: "",
                password: "",
                client_name: "",
            };
        },
    },
};
