export default {
    name: "tool-manager-side",
    components: {},
    props: {
        item: {
            type: Array,
        },
        tool_id: {
            type: String,
        },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        add_form(value) {
            this.$emit("handle_form", value);
        },
    },
};
