import ToolManagerForm from "@/components/toolmanager-form";
import ToolManagerSide from "@/components/toolmanager-side";
import {mapActions, mapGetters} from "vuex";
export default {
    name: "tool-secret",
    components: {
        ToolManagerForm,
        ToolManagerSide,
    },
    props: {},
    data() {
        return {
            tools: {
                Tool1: [{label: "api key", type: "input"}],
                Tool2: [{label: "api key", type: "input"}],
                Tool3: [
                    {label: "username", type: "input"},
                    {label: "password", type: "password"},
                ],
            },
            toolsData: [],
            selected_tool: "",
        };
    },
    computed: {
        ...mapGetters({
            getAllData: "toolmanager/getToolData",
        }),
    },
    mounted() {
        this.setAllData();
    },
    methods: {
        ...mapActions({
            setAllData: "toolmanager/getToolManagerData",
        }),
        handle_form(data) {
            this.toolsData = data;
            this.selected_tool = data.id;
        },
    },
};
